// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphqlAnonymous:'https://dev-dcp.pocnconnect.com/graphql',
  graphqlShielded:'https://dev-dcp.pocnconnect.com/shield/graphql',


  idpLoginURL:"https://dev-dcp.pocnconnect.com/auth/realms/POCN/protocol/openid-connect/auth?client_id=pocnconnect&redirect_uri=https%3A%2F%2Fdev.pocnconnect.com%2Fpre-authorization%2F&state=681051d9-5573-4830-8fab-297aef046df2&response_mode=fragment&response_type=code&scope=openid",
  //idpLoginURL:'https://dev-dcp.pocnconnect.com/auth/realms/POCN/protocol/openid-connect/auth?client_id=pocnconnect&redirect_uri=http%3A%2F%2Flocalhost%3A8100%2Fpre-authorization%2F&state=681051d9-5573-4830-8fab-297aef046df2&response_mode=fragment&response_type=code&scope=openid',

  //idpLogoutURL:'https://dev-dcp.pocnconnect.com/auth/realms/POCN/protocol/openid-connect/logout/?redirect_uri=http%3A%2F%2Flocalhost%3A8100%2F',
  idpLogoutURL:'https://dev-dcp.pocnconnect.com/auth/realms/POCN/protocol/openid-connect/logout/?redirect_uri=https%3A%2F%2Fdev.pocnconnect.com%2F',

  //idpredirectURL:'http://localhost:8100/pre-authorization/',
  idpredirectURL:'https://dev.pocnconnect.com/pre-authorization/',

  twilioServerURL:"https://dev-dialer-api.pocnconnect.com"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
