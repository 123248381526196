import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { WelcomePage } from './pages/welcome/welcome.page';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
   {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'story/:id',
    loadChildren: () => import('./pages/story-viewer/story-viewer.module').then( m => m.StoryViewerPageModule)
  },
  {
    path: 'post-detail',
    loadChildren: () => import('./pages/post-detail/post-detail.module').then( m => m.PostDetailPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then( m => m.ContactPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'comments',
    loadChildren: () => import('./pages/comments/comments.module').then( m => m.CommentsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'event-detail',
    loadChildren: () => import('./pages/event-detail/event-detail.module').then( m => m.EventDetailPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },

  {
    path: '',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },

  {
    path: 'pre-authorization',
    loadChildren: () => import('./pages/pre-authorization/pre-authorization.module').then( m => m.PreAuthorizationPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'login-error',
    loadChildren: () => import('./pages/login-error/login-error.module').then( m => m.LoginErrorPageModule)
  },
  {
    path: 'call-history',
    loadChildren: () => import('./pages/call-history/call-history.module').then( m => m.CallHistoryPageModule)
  },
  {
    path: 'dailer-settings',
    loadChildren: () => import('./pages/dailer-settings/dailer-settings.module').then( m => m.DailerSettingsPageModule)
  },
  {
    path: 'preference',
    loadChildren: () => import('./pages/preference/preference.module').then( m => m.PreferencePageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)
},
{
  path: 'user/:userId',
  loadChildren: () => import('./pages/public-profile/public-profile.module').then( m => m.PublicProfilePageModule)
},   {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'feed',
    loadChildren: () => import('./pages/feed/feed.module').then( m => m.FeedPageModule)
  },
  {
    path: 'modal-popover',
    loadChildren: () => import('./modal-popover/modal-popover.module').then( m => m.ModalPopoverPageModule)
  },
  {
    path: 'group/:groupId',
    loadChildren: () => import('./pages/group-detail/group-detail.module').then( m => m.GroupDetailPageModule)
  },
  {
    path: 'reject-modal',
    loadChildren: () => import('./pages/reject-modal/reject-modal.module').then( m => m.RejectModalPageModule)
  },
  {
    path: 'email-modal',
    loadChildren: () => import('./pages/email-modal/email-modal.module').then( m => m.EmailModalPageModule)
  },
  {
    path: 'mobile-pre-auth',
    loadChildren: () => import('./pages/mobile-pre-auth/mobile-pre-auth.module').then( m => m.MobilePreAuthPageModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/connect/connect.module').then( m => m.ConnectPageModule)
  },
  {
    path: 'sign-modal',
    loadChildren: () => import('./pages/sign-modal/sign-modal.module').then( m => m.SignModalPageModule)
  },
  {
    path: 'profile/:npi',
    loadChildren: () => import('./pages/mdm-profile/mdm-profile.module').then( m => m.MdmProfilePageModule)
  },  {
    path: 'post-popover',
    loadChildren: () => import('./pages/post-popover/post-popover.module').then( m => m.PostPopoverPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
