import { HttpHeaders } from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS,APOLLO_NAMED_OPTIONS} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { environment } from './../environments/environment';

@NgModule({
    exports: [ApolloModule, HttpLinkModule],
    providers: [
          {
              provide: APOLLO_OPTIONS,
              deps: [HttpLink],
              useFactory: createDefaultApollo
          },
          {
              provide: APOLLO_NAMED_OPTIONS,
              deps: [HttpLink],
              useFactory: createNamedApollo
          }
      ]
    
  })

export class GraphQLModule {
    constructor() {}
}

const anonymousUri = environment.graphqlAnonymous;
const shieldedUri = environment.graphqlShielded;

export function createDefaultApollo(httpLink: HttpLink){
    return {
        link: httpLink.create({ uri: anonymousUri }),
        cache: new InMemoryCache()
    };
}

const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('pocnApiAccessToken');

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
      'auth-strategy': "next",
      'realm': "POCN",
      Authorization: `Bearer ${token}`
    }
      };
    }
  });

export function createNamedApollo(httpLink: HttpLink) {
    return {
        second: {
            name: 'second',
            link: ApolloLink.from([basic, auth, httpLink.create({ uri: shieldedUri })]),
            cache: new InMemoryCache(),
        }
    };
}




