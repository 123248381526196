import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
  ) {

    App.addListener('appUrlOpen', data => {
      console.log('App opened with URL:', data);
      const accessCode = data.url.split("&code=").pop();
      const cheCkToken = accessCode.indexOf("https:/") > -1
      if (!cheCkToken) {
        const params: NavigationExtras = {
          state: { url: data.url, code: accessCode }
        };
        this.router.navigate(['/mobile-pre-auth'], params)
      }else{
        this.router.navigate([''])
      }
    });
  }
}
